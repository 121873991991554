import React from "react";
import { AppBar, Toolbar, Button, Box, Typography } from "@mui/material";

import laizalogo from "../assets/laizalogo.svg";

const Header = () => (
  <AppBar
    position="static"
    color="transparent"
    elevation={0}
    className="header"
  >
    <Toolbar sx={{ justifyContent: "center", marginTop: "15px" }}>
      <Box
        display="flex"
        flexDirection="column"
        alignItems="center"
        justifyContent="center"
        textAlign="center"
      >
        <Typography variant="h2" className="future-ecommerce-title">
          A Brand of Greatminds Retail Private Limited
        </Typography>
        <img src={laizalogo} alt="Laiza Logo" loading="lazy" width="350" />
      </Box>
    </Toolbar>
  </AppBar>
);

export default Header;
